export const activeIndexes = new Set<number>();

export const getNextSmallestIndex = (maxItems: number) => {
  for (let i = 0; i < maxItems; i++) {
    if (!activeIndexes.has(i)) {
      return i;
    }
  }
};

export const getNextBiggestIndex = () => {
  const arr = [...Array.from(activeIndexes)];
  const biggestIndex = Math.max(...arr) + 1;
  return biggestIndex;
};

export const getNextAvailableIndex = (maxItems: number): number | undefined => {
  if (!activeIndexes.size) {
    return 0;
  }
  const biggestIndex = getNextBiggestIndex();
  const smallestIndex = getNextSmallestIndex(maxItems);
  const nextIndex = biggestIndex !== maxItems ? biggestIndex : smallestIndex;

  return nextIndex;
};

export const activateNextAvailableIndex = (maxItems: number, deleteIndex: number) => {
  const nextIndex = getNextAvailableIndex(maxItems);
  activeIndexes.delete(deleteIndex);
  activateCustomIndex(maxItems, nextIndex!);
};

export const activateCustomIndex = (maxItems: number, nextIndex: number) => {
  activeIndexes.add(nextIndex);
  if (activeIndexes.size === maxItems + 1) {
    pop();
  }
};

export const pop = () => {
  const firstItem = activeIndexes.keys().next().value;
  activeIndexes.delete(firstItem);
};
