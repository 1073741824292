'use client';

import NextImage, { ImageProps as NextImageProps } from 'next/image';

export const MeetingsImageClient = (props: NextImageProps) => (
  <div className="group relative">
    <NextImage
      {...props}
      className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
      role="presentation"
    />
    <div className="pointer-events-none absolute inset-0 rounded-xl bg-background-800/10 ring-1 ring-inset ring-gray-900/10 transition-colors group-hover:bg-transparent dark:ring-white/30" />
  </div>
);

export default MeetingsImageClient;
