'use client';

import { IframeHTMLAttributes, useEffect, useState } from 'react';

import { useInView } from 'react-intersection-observer';

export const Iframe = (props: IframeHTMLAttributes<HTMLIFrameElement>) => {
  const [hasLoaded, setHasLoaded] = useState(false);

  const { ref, inView } = useInView({
    rootMargin: '200px',
  });

  useEffect(() => {
    if (inView) {
      setHasLoaded(true);
    }
  }, [inView]);

  return (
    <div ref={ref} className="w-full">
      {hasLoaded && <iframe ref={ref} {...props}></iframe>}
    </div>
  );
};
