'use client';

import { VideoCameraIcon } from '@heroicons/react/24/outline';

import { HomePage } from '@/types/sanity.types';
import { Anchor, ButtonGhost } from './Buttons';

type HeroComponentProps = {
  hero: HomePage['hero'];
  children: JSX.Element;
};

export const Hero = ({ hero, children: image }: HeroComponentProps) => {
  return (
    <div className="relative overflow-hidden bg-cover pt-14">
      {image}
      {imageGradient}
      {randomSplashesOfColor}

      <div className="container relative z-20 mx-auto px-10 py-32 pb-10 sm:py-48">
        {/* <div className="hidden sm:mb-8 sm:flex sm:justify-center">
          <div className="relative rounded-full px-3 py-1 text-sm leading-6 text-gray-400 ring-1 ring-white/10 hover:ring-white/20">
            Some call to action.
            <a href="#" className="font-semibold text-white">
              <span className="absolute inset-0" aria-hidden="true" />
              Read more <span aria-hidden="true">&rarr;</span>
            </a>
          </div>
        </div> */}
        <div className="text-center">
          <h1 className="text-4xl font-bold tracking-tight text-white sm:text-6xl">{hero?.title}</h1>
          {/* <p className="mt-6 text-lg leading-8 text-gray-300">
            Some  heading
          </p> */}
          <div className="mt-10 flex flex-col items-center justify-center gap-4 whitespace-nowrap sm:flex-row sm:gap-6">
            <Anchor target="_blank" href="https://www.youtube.com/channel/UC3dUN7rxWhoPFxEoNCLGZ_g/playlists">
              <VideoCameraIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
              {hero?.sermon_link_text}
            </Anchor>
            <ButtonGhost onClick={() => window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })}>
              {hero?.map_link_text} <span aria-hidden="true">→</span>
            </ButtonGhost>
          </div>
        </div>
      </div>
    </div>
  );
};

const imageGradient = (
  <div
    className="absolute inset-0 select-none 
  bg-[linear-gradient(to_right_bottom,rgba(61,56,115,0.45),rgba(49,46,79,0.8))] 
  transition-all 
  dark:bg-[linear-gradient(to_right_bottom,rgba(4,2,25,0.8),rgba(4,2,25,0.8))]"
  ></div>
);

const randomSplashesOfColor = (
  <>
    <div
      className="absolute inset-x-0 -top-40 z-10 transform-gpu select-none overflow-hidden blur-3xl transition-all sm:-top-80"
      aria-hidden="true"
    >
      <div
        className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-20 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
        style={{
          clipPath:
            'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
        }}
      />
    </div>
    <div
      className="absolute inset-x-0 top-[calc(100%-13rem)] z-10 transform-gpu select-none overflow-hidden blur-3xl transition-all sm:top-[calc(100%-30rem)]"
      aria-hidden="true"
    >
      <div
        className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-20 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
        style={{
          clipPath:
            'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
        }}
      />
    </div>
  </>
);
