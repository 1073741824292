import classNames from 'classnames';
import { AnchorHTMLAttributes, ButtonHTMLAttributes } from 'react';

const colorCSS =
  'text-white bg-background-500 hover:bg-background-400 dark:bg-background-600 dark:hover:bg-background-500';

const ghostCSS = 'text-white hover:bg-white/10';

const focusCSS = 'focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-accent-600';

const disabledCSS = 'disabled:opacity-50 disabled:cursor-not-allowed';

const otherCSS = classNames(
  focusCSS,
  disabledCSS,
  'inline-flex items-center gap-x-2 rounded-md px-3.5 py-2.5 text-sm tracking-wide font-semibold shadow-sm transition-all cursor-pointer',
);

export const Anchor = (props: AnchorHTMLAttributes<HTMLAnchorElement>) => {
  return <a {...props} className={classNames(colorCSS, otherCSS, props.className)} role="button" />;
};

export const Button = (props: ButtonHTMLAttributes<HTMLButtonElement>) => {
  return <button {...props} className={classNames(colorCSS, otherCSS, props.className)} />;
};

export const ButtonGhost = (props: ButtonHTMLAttributes<HTMLButtonElement>) => {
  return <button {...props} className={classNames(ghostCSS, otherCSS, props.className)} />;
};
