/* eslint-disable react-hooks/exhaustive-deps */
'use client';

import useSWR from 'swr';
import dynamic from 'next/dynamic';
import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { getNextAvailableIndex, activateCustomIndex, activateNextAvailableIndex } from '@/utils/flip';

type FlipProps = {
  children: JSX.Element;
  className?: string;
  defaultIndex: number;
  maxItems: number;
  apiRoute: string;
  componentPath: string;
};

const DELAY = 0.8;

const fetcher = (url: string) => fetch(url).then((res) => res.json());

export const Flip = ({ children, className, defaultIndex, maxItems }: FlipProps) => {
  const [front, setFront] = useState<JSX.Element | undefined>(children);
  const [back, setBack] = useState<JSX.Element | undefined>();
  const [flipToggle, setFlipToggle] = useState<boolean>(false);
  const [nextIndex, setNextIndex] = useState<number | undefined>();
  const [flipping, setFlipping] = useState<boolean | undefined>();
  const [loading, setLoading] = useState<boolean | undefined>();
  const [hovered, setHovered] = useState<boolean>(false);
  const currentIndex = useRef<number>(defaultIndex);

  const nextComponent = useRef<JSX.Element>();

  const { data: props, error } = useSWR(
    typeof nextIndex !== 'undefined' ? `meetings-image?index=${nextIndex}` : undefined,
    fetcher,
  );

  const update = () => {
    if (!flipping && loading === false) {
      if (flipToggle) {
        setFront(nextComponent.current);
      } else {
        setBack(nextComponent.current);
      }
    }
  };

  const updateIndex = () => {
    setLoading(true);
    setNextIndex(getNextAvailableIndex(maxItems));
  };

  useEffect(() => {
    if (props && !error) {
      const DynamicComponent = dynamic(() => import('./home/MeetingsImageClient'), { ssr: false });
      const component = <DynamicComponent {...props} />;
      nextComponent.current = component;
      if (loading === undefined) {
        setBack(component);
      }
      setLoading(false);
    }
  }, [props, error]);

  useEffect(() => update, [flipping, loading, nextComponent.current, nextIndex, flipToggle]);

  useEffect(() => {
    activateCustomIndex(maxItems, defaultIndex);
    window.addEventListener('NEW_GLOBAL_INDEX', updateIndex);
    setTimeout(updateIndex);
  }, []);

  const flip = () => {
    if (flipping || loading || hovered) {
      return;
    }

    setFlipping(true);
    setLoading(true);
    setHovered(true);

    setFlipToggle((flipToggle) => !flipToggle);
    setTimeout(() => setFlipping(false), DELAY * 1000);

    activateNextAvailableIndex(maxItems, currentIndex.current);
    currentIndex.current = nextIndex ?? defaultIndex;

    window.dispatchEvent(new Event('NEW_GLOBAL_INDEX'));
  };

  return (
    <div
      onMouseOver={flip}
      onMouseLeave={() => setHovered(false)}
      className={classNames('group', className)}
      style={{ perspective: '600px' }}
    >
      <div
        className={classNames(`h-full w-full transition-all`, {
          '[transform:rotateY(180deg)]': flipToggle,
        })}
        style={{ transformStyle: 'preserve-3d', transitionDuration: `${DELAY}s` }}
      >
        <div className="absolute h-full w-full [backface-visibility:hidden]">{front}</div>
        <div className="absolute h-full w-full [transform:rotateY(180deg)] [backface-visibility:hidden]">{back}</div>
      </div>
    </div>
  );
};
