'use client';

import classNames from 'classnames';
import { useInView } from 'react-intersection-observer';

export const Parallax = ({ children }: { children: JSX.Element[] }) => {
  const { ref, inView } = useInView();

  return (
    <div
      ref={ref}
      className={classNames('relative bg-accent-900/40 transition-colors dark:bg-accent-900/40', {
        'opacity-0': !inView,
      })}
    >
      {children}
    </div>
  );
};
